import React from "react";
import { Slide } from "react-slideshow-image";
import 'react-slideshow-image/dist/styles.css'
import "../styles/App.css";
import styles from "../styles/Needlepoint.module.css";
import redChair from "../assets/needlepoint/chair.jpg";
import greyChair from "../assets/needlepoint/chair_2.jpg";

import pillowAngel from "../assets/needlepoint/pillows/pillow_angel.jpg";
import pillowBack from "../assets/needlepoint/pillows/pillow_back.jpg";
import pillowBand from "../assets/needlepoint/pillows/pillow_band.jpg";
import pillowBird from "../assets/needlepoint/pillows/pillow_bird.jpg";
import pillowBlocks from "../assets/needlepoint/pillows/pillow_blocks.jpg";
import pillowBlue from "../assets/needlepoint/pillows/pillow_blue.jpg";
import pillowCat from "../assets/needlepoint/pillows/pillow_cat.jpg";
import pillowCats from "../assets/needlepoint/pillows/pillow_cats.jpg";
import pillowCircles from "../assets/needlepoint/pillows/pillow_circles.jpg";
import pillowDiamonds from "../assets/needlepoint/pillows/pillow_diamonds.jpg";
import pillowElephant from "../assets/needlepoint/pillows/pillow_elephant.jpg";
import pillowFlamingo from "../assets/needlepoint/pillows/pillow_flamingo.jpg";
import pillowFruit from "../assets/needlepoint/pillows/pillow_fruit.jpg";
import pillowHoneycomb from "../assets/needlepoint/pillows/pillow_honeycomb.jpg";
import pillowHorses from "../assets/needlepoint/pillows/pillow_horses.jpg";
import pillowLizard from "../assets/needlepoint/pillows/pillow_lizard.jpg";
import pillowPeonies from "../assets/needlepoint/pillows/pillow_peonies.jpg";
import pillowPink from "../assets/needlepoint/pillows/pillow_pink.jpg";
import pillowRabbit from "../assets/needlepoint/pillows/pillow_rabbit.jpg";
import pillowWoman from "../assets/needlepoint/pillows/pillow_woman.jpg";
import pillowYinyang from "../assets/needlepoint/pillows/pillow_yinyang.jpg";

import escher from "../assets/needlepoint/jewelry_holders/escher.jpg";
import holders from "../assets/needlepoint/jewelry_holders/jewelry_holders.jpg";
import sheep from "../assets/needlepoint/jewelry_holders/sheep.jpg";
import wheat from "../assets/needlepoint/jewelry_holders/wheat.jpg";
import strawberries from "../assets/needlepoint/jewelry_holders/strawberries.jpg";

import animals from "../assets/needlepoint/wall_art/animals.jpg";
import bonzo from "../assets/needlepoint/wall_art/bonzo.jpg";
import cow from "../assets/needlepoint/wall_art/cow.jpg";
import fallon from "../assets/needlepoint/wall_art/fallon.jpg";
import sloane from "../assets/needlepoint/wall_art/sloane.jpg";
import library from "../assets/needlepoint/wall_art/library.jpg";
import pandas from "../assets/needlepoint/wall_art/pandas.jpg";
import roosters from "../assets/needlepoint/wall_art/roosters.jpg";
import socks from "../assets/needlepoint/wall_art/socks.jpg";
import sisters from "../assets/needlepoint/wall_art/three_sisters.jpg";
import window from "../assets/needlepoint/wall_art/window.jpg";

import keyHolder from "../assets/needlepoint/keys.jpg";
import keys from "../assets/needlepoint/key_rings.jpg";
import squares from "../assets/needlepoint/squares.jpg";
import aSquares from "../assets/needlepoint/mobile.jpg";
import chess from "../assets/needlepoint/chess.jpg";
import recorders from "../assets/needlepoint/recorder_cases.jpg";
import doll from "../assets/needlepoint/andrea_doll.jpg";

const pillowImages = [{
    image: pillowAngel,
    caption: 'Angel Amore. Gift to friend. 2015'
  },
  {
    image: pillowBird,
    caption: 'Bluebird. Gift to friend. 2021'
  },
  {
    image: pillowBack,
    caption: "I've Got Your Back. Sold. 2023"
  },
  {
    image: pillowBand,
    caption: 'Pillow based on image from the book Snow White in New York, by Fiona French (Oxford University Press,1989). Gift to granddaughter. 1990s'
  },
  {
    image: pillowBlocks,
    caption: 'Geometric. Sold. 2017'
  },
  {
    image: pillowBlue,
    caption: 'Interlocking stripes. Gift to granddaughter. 2015'
  },
  {
    image: pillowCat,
    caption: 'Geometric cat. Unsold. 2020'
  },
  {
    image: pillowCats,
    caption: 'Cats on Tile floor. Gift to friend. 2012'
  },
  {
    image: pillowCircles,
    caption: 'Concentric Circles. Gift to friend. Circa 2010'
  },
  {
    image: pillowDiamonds,
    caption: 'Triangles & Squares. Sold. 2022'
  },
  {
    image: pillowElephant,
    caption: 'Elephant. Gift to grandson. 1990s'
  },
  {
    image: pillowFlamingo,
    caption: 'Stylized Pink Swan. Unsold. 2021'
  },
  {
    image: pillowFruit,
    caption: 'Orange. Sold. 1980s. And Citrus. Unsold. 2014'
  },
  {
    image: pillowHoneycomb,
    caption: 'Bee & Beehive. Very first needlepoint. Personal collection. 1979. And interlocking Ovals. Personal collection. 1980'
  },
  {
    image: pillowHorses,
    caption: 'End papers from Ancient Thunder by Leo Yerxa (Groundwood Books, 2012). Private collection. 2006'
  },
  {
    image: pillowLizard,
    caption: 'Lizard. Unsold. 2021'
  },
  {
    image: pillowPeonies,
    caption: 'Flowers. Sold. 2010s'
  },
  {
    image: pillowPink,
    caption: 'Peony. Unsold. 2010s'
  },
  {
    image: pillowRabbit,
    caption: 'Stylized Rabbit. Personal collection. 2016'
  },
  {
    image: pillowYinyang,
    caption: 'Yin and Yang Pillow. 2001'
  },
];

const wallArtImages = [
{
  image: fallon,
  caption: 'Fishes for Fallon. Gift to great niece. 2022'
},
{
  image: sloane,
  caption: 'Seashells, Seahorses and Seastars for Sloane. Gift to great niece. 2022'
},
{
  image: bonzo,
  caption: 'Image from The Mouse and his Child, by Russel Hoban (Harper & Row, 2967). Gift to son. 1987'
},
{
  image: pillowWoman,
  caption: 'Woman in profile. Gift to daughter. 2016'
},
{
  image: library,
  caption: 'Library Lady. Personal Collection. 1990'
},
{
  image: roosters,
  caption: 'Roosters. Personal collection. 2017'
},
{
  image: socks,
  caption: 'Socks Hanging on the Clothesline. Image by Dick Bruna. 2014'
},
{
  image: window,
  caption: 'Cat in Window. Gift to friend. 1980s'
},
{
  image: pandas,
  caption: 'Panda bears. Gift to family friends. 1980s'
},
{
  image: cow,
  caption: 'Pig in Meadow. Gift to granddaughter. 2010s'
},
{
  image: animals,
  caption: 'Beatrix Potter characters. Donated for fundraiser. 1990s'
},
{
  image: sisters,
  caption: 'Three sisters. Gift to family friends. 1980s'
},
];

const proprietes = {
  duration: 5000,
  autoplay: false,
  transitionDuration: 500,
  infinite: true,
  easing: "ease",
}

export default function Needlepoint() {
  return (
    <div className={styles.needlepoint_container}>
      <div className={styles.needlepoint_introduction}>
        I’ve been a needlepointer since the mid-1970s. 
        Over the years I have created wall hangings, pillows, seat cushion covers, 
        jewellery holders, keyrings, and even a doll. 
        Unfortunately needlepoint has fallen out of fashion. 
        It does take time to create even a small pillow. 
        But I find stitching wool or silk on canvas relaxing and rewarding. 
        I’ve made nearly 100 needlepoint works, all of them one-of-a-kind. 
        Many became gifts for family and friends. Here are a few examples.
      </div>
      <hr></hr>
      <div className={styles.section_titles}>
        CHAIRS
      </div>
      <div className={styles.grid_section_1}>
        <div className={styles.grid_sections}>
          <img src={redChair} alt="red chair"></img>
          <div className={styles.grid_section_1_caption}>
            "Reading" Rocking Chair. Gift to friend. 2013
          </div>
        </div>
        <div className={styles.grid_sections}>
          <img src={greyChair} alt="grey chair"></img>
          <div className={styles.grid_section_1_caption}>
            Sewing Chair. Gift to sister Anna Maria. 2022
          </div>
        </div>
      </div>

      <div className={styles.section_titles}>
        PILLOWS
      </div>
      <div className={styles.slideshow_container}>
        <Slide {...proprietes}>
          {pillowImages.map((slideImage, index)=> (
            <div key={index} className={styles.slides}>
              <img src={slideImage.image} alt={slideImage.caption}></img>
              <span>{slideImage.caption}</span>
            </div>
          ))}
        </Slide>
      </div>

      <div className={styles.section_titles}>
        WALL ART
      </div>
      <div className={styles.slideshow_container}>
        <Slide {...proprietes}>
          {wallArtImages.map((slideImage, index)=> (
            <div key={index} className={styles.slides}>
              <img src={slideImage.image} alt={slideImage.caption}></img>
              <span>{slideImage.caption}</span>
            </div>
          ))}
        </Slide>
      </div>

      <div className={styles.section_titles}>
        JEWELRY HOLDERS
      </div>
      <div className={styles.grid_section_2}>
        <div className={styles.grid_sections}>
          <img className={styles.img_15} src={escher} alt="Escher inspired jewelry holder"></img>
          <div className={styles.caption_15}>
            Jewellery holder based on Escher art. Gift to daughter. 1980s
          </div>
        </div>
        <div className={styles.grid_sections}>
          <img className={styles.img_15} src={holders} alt="Colorful jewelry holders"></img>
          <div className={styles.caption_15}>
            &#40;Left&#41; Concentric Circles. Gift to sister. 2015. &#40;Right&#41; Birds. Gift to friend. 2015
          </div>
        </div>
        <div className={styles.grid_sections}>
          <img className={styles.img_15} src={sheep} alt="Sheep jewelry holder"></img>
          <div className={styles.caption_15}>
            Grazing sheep. Gift to daughter-in-law. 1980s
          </div>
        </div>
      </div>
      <div className={styles.grid_section_3}>
        <div className={styles.grid_sections}>
          <img className={styles.img_15} src={wheat} alt="Wheat"></img>
          <div className={styles.caption_15}>
            Wheat sheafs. Sold. 2022
          </div>
        </div>
        <div className={styles.grid_sections}>
          <img className={styles.img_15} src={strawberries} alt="Strawberry jewelry holder"></img>
          <div className={styles.caption_15}>
            Strawberries. Gift to friend. 2022
          </div>
        </div>
      </div>

      <div className={styles.section_titles}>
        MISCELLANEOUS
      </div>
      <div className={styles.grid_section_2}>
        <div className={styles.grid_sections}>
          <img className={styles.img_15} src={doll} alt="Andrea doll"></img>
          <div className={styles.caption_15}>
            "Andrea Doll" 1981
          </div>
        </div>
        <div className={styles.grid_sections}>
          <img className={styles.img_15} src={recorders} alt="Recorder cases"></img>
          <div className={styles.caption_15}>
          Recorder cases. 1982
          </div>
        </div>
        <div className={styles.grid_sections}>
          <img className={styles.img_15} src={chess} alt="Chess board"></img>
          <div className={styles.caption_15}>
            Chess Board. 1980s
          </div>
        </div>
      </div>

      <div className={styles.grid_section_1}>
        <div className={styles.grid_sections}>
          <img src={keyHolder} alt="Key holder"></img>
          <div className={styles.grid_section_1_caption}>
            Keyring holder and keyrings. 1982
          </div>
        </div>
        <div className={styles.grid_sections}>
          <img src={keys} alt="Key rings"></img>
          <div className={styles.grid_section_1_caption}>
            Key Rings. 1982
          </div>
        </div>

        <div className={styles.grid_sections}>
          <img src={aSquares} alt="A is for apple"></img>
          <div className={styles.grid_section_1_caption}>
            "A is for Apple, Angel, and Arrow." Plastic mesh canvas for possible mobile. 1980s
          </div>
        </div>
        <div className={styles.grid_sections}>
          <img src={squares} alt="Squares for mobile"></img>
          <div className={styles.grid_section_1_caption}>
            Plastic mesh squares for possible mobile.1980s
          </div>
        </div>
      </div>
    </div>
  );
}