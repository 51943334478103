import { useNavigate }  from "react-router-dom";
import "../styles/App.css";
import styles from "../styles/BackButton.module.css";

export default function BackButton() {
let navigate = useNavigate();
  return (
    <div className={styles.back_button} onClick={() => navigate(-1)}>
    </div>
  );
}