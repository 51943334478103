import "../styles/App.css";
import styles from "../styles/Footer.module.css";

export default function Footer() {
  return (
    <div className={styles.footer_container}>
      <hr></hr>
        <div>
            Copyright Theo Heras
        </div>
        <div>
            Website design by Amy Cachero
        </div>
    </div>
  );
}