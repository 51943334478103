import { useEffect } from "react";
import BackButton from "../../components/GoBack";
import styles from "../../styles/BooksDetails.module.css";
import hat from "../../assets/bookCovers/hat_on_hat_off.jpg";

export default function HatOnHatOff() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.books_details_container}>
            <BackButton></BackButton>
            <div className={styles.details_title}>
                HAT ON HAT OFF
            </div>
            <div className={styles.details}>
                <div className={styles.details_text}>
                    <div>
                        Illustrated by Renné Benoit. Published by Pajama Press in 2016.
                    </div>
                    <br></br>
                    <div>
                        The title says it all. Getting a toddler ready for a walk entails trying to keep a hat on.
                    </div>
                </div>
                <img className={styles.details_book_cover} src={hat} alt="'Hat On Hat Of' book cover"></img>
            </div>
            <div className={styles.purchase_links}>
                <a target="_blank" rel="noreferrer" href="https://a.co/d/7nOFLyQ">
                    AMAZON LINK
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.indigo.ca/en-ca/hat-on-hat-off/9781772782141.html">
                    INDIGO LINK
                </a>
            </div>
            <div className={styles.reviews_section}>
                <div className={styles.reviews_title}>
                    REVIEWS
                </div>
                <div className={styles.reviews}> 
                    <div>
                        <div>
                            "Theo Heras uses spare and simple language to tell a story that toddlers and preschoolers 
                            (and certainly their parents and caregivers) are sure to recognize. 
                            Renné Benoit's soft illustrations bring the antics to life. 
                            From the assortment of fun and funky hats piled up in a basket to the facial expressions of 
                            the toddler in question, Benoit demonstrates an eye for details that matter. . . . 
                            This book has a potential interactive quality that little ones are sure to enjoy."
                        </div>
                        <div>
                            &emsp;~ Canadian Children's Booknews, Spring <br></br>&emsp; 2017
                        </div>
                    </div>
                    <div>
                        <div>
                            "The staccato phrases are extended by the charning watercolour-and-digital illustrations 
                            that bleed off the page, creating an intimate, up-close effect. 
                            Soft colours and background patterns of knitted yarn (which only adults will notice) 
                            add a cozy feeling to the text pages. Heavyweight paper and rounded corners will help little 
                            hands to turn the pages easily. A seemingly simple story is greatly enhanced by nuanced, 
                            toddler-friendly details."
                        </div>
                        <div>
                            &emsp;~ Kirkus, July 15, 2016
                        </div>
                    </div>
                    <div>
                        <div>
                            "Theo Heras' text is not complex but that makes it easy to follow for those whom Hat On, 
                            Hat Off is written. . . . . . [They'll especially be hooked by Renné Benoit's endearing 
                            illustrations. . . With knitted caps of varying colours and textures (very important in a hat) 
                            and design elements, Hat On, Hat Off [is] a joyful experience of style and taste and childish 
                            amusement."
                        </div>
                        <div>
                            &emsp;~ Canlit for Little Children, posted <br></br>&emsp; September 13, 2016
                        </div>
                    </div>
                    <div>
                        <div>
                            "Hat On, Hat Off is a lovely little book for young children and parents, 
                            all of whom will identify with the story. The simple language and straightforward 
                            illustrations aid in the ability for young children just learning to speak to interact 
                            with the book."
                        </div>
                        <div>
                            &emsp;~ CM Magazine, September 23, 2016
                        </div>
                    </div>
                    <div>
                        <div>
                            "Hat On, Hat Off is a wonderful read-a-loud title for young children that should be 
                            incorporated into library and classroom collections without hesitation. Highly Recommended."
                        </div>
                        <div>
                            &emsp;~ Resource Links, October 1, 2016
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}