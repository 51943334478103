import { useEffect } from "react";
import BackButton from "../../components/GoBack";
import styles from "../../styles/BooksDetails.module.css";
import babyCakes from "../../assets/bookCovers/baby_cakes.jpg";

export default function BabyCakes() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.books_details_container}>
            <BackButton></BackButton>
            <div className={styles.details_title}>
                BABY CAKES
            </div>
            <div className={styles.details}>
                <div className={styles.details_text}>
                    <div>
                        Illustrated by Renné Benoit. Published by Pajama Press in 2017.
                    </div>
                    <br></br>
                    <div>
                        The intrepid duo of Hat On, Hat Off are in the kitchen baking up a storm. 
                        If only kitty could stay out of trouble!
                    </div>
                </div>
                <img className={styles.details_book_cover} src={babyCakes} alt="'Baby Cakes' book cover"></img>
            </div>
            <div className={styles.purchase_links}>
                <a target="_blank" rel="noreferrer" href="https://a.co/d/fCeDer5">
                    AMAZON LINK
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.indigo.ca/en-ca/baby-cakes/9781772782349.html">
                    INDIGO LINK
                </a>
            </div>
            <div className={styles.reviews_section}>
                <div className={styles.reviews_title}>
                    REVIEWS
                </div>
                <div className={styles.reviews}> 
                    <div>
                        <div>
                            "A sister and her younger brother set out to make a teatime snack 
                            in this genial book for little hands. Big, bold text in simple, 
                            direct sentences pairs perfectly with Benoit's luminous watercolor artwork ... . 
                            This pleasant book makes baking look like so much fun that kiddos are likely 
                            to be inspired to try to help out in the kitchen."
                        </div>
                        <div>
                            &emsp;~ Sarah Hunter, Booklist, September 1, 2017
                        </div>
                    </div>
                    <div>
                        <div>
                            "The declarative, sometimes imperative, text is as straightforward as a recipe. 
                            Although [Mommy] is in the kitchen, the focus is on the children's activities ... . 
                            Benoit's art features distinct outlines, rounded figures, and soft colors -- 
                            the mutual affection is apparent on every page. A recipe for success."
                        </div>
                        <div>
                            &emsp;~ Kirkus, October 1, 2017
                        </div>
                    </div>
                    <div>
                        <div>
                            "The toddlers who starred in Hat On, Hat Off are now preparing cupcakes in the kitchen ... . 
                            Using the common theme of baking with the anticipation of the tasting that comes later, 
                            Heras relates a simple tale with vocabulary stretching words and phrases ... . 
                            Benoit offers a wide variety of perspectives ... . A sweet treat that's sure to please."
                        </div>
                        <div>
                            &emsp;~ Blair Christolon, School Library Journal, <br></br>&emsp; October 1, 2017
                        </div>
                    </div>
                    <div>
                        <div>
                            "Baby Cakes leads young readers through the sometimes grimy -- but oh-so-fun -- 
                            process of baking muffins [sic], showcasing the kitchen adventures of two young 
                            siblings and their feisty kitten. ... The text is presented as short, catchy sentences 
                            and phrases and is complemented by colourful illustrations. Recommended."
                        </div>
                        <div>
                            &emsp;~ Roxy Garstad, CM Magazine, November 10, <br></br>&emsp; 2017
                        </div>
                    </div>
                    <div>
                        <div>
                            "Young children will be eager to try out baking in the kitchen after reading this sweet board book. 
                            Baby Cakes is written in simple and accessible language and delivered in a sturdy format for young hands. . . . 
                            Renné Benoit's adroit and totally appealing illustrations of childhood are another treat and she perfectly 
                            captures the fleeting nature of these small familiar moments of curious children. . . and cats!"
                        </div>
                        <div>
                            &emsp;~ Anne Letain, Resource Links, December 21, <br></br>&emsp; 2017
                        </div>
                    </div>
                    <div>
                        <div>
                            "Told in simple text by Theo Heras, charmingly illustrated on every page by Renné Benoit, 
                            and delivered in a sturdy, padded cover with stronger pages that are perfect for little hands, 
                            Baby Cakes. . . is especially recommended for children ages 1 to 3, making it appropriate for family, 
                            daycare center, preschool, and community library collectionss."
                        </div>
                        <div>
                            &emsp;~ Midwest Book Review, February 1, 2018
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}