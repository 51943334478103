import { useEffect } from "react";
import BackButton from "../../components/GoBack";
import styles from "../../styles/BooksDetails.module.css";
import babyO from "../../assets/bookCovers/what_will_we_do_with_the_baby_o.jpg"

export default function BabyO() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.books_details_container}>
            <BackButton></BackButton>
            <div className={styles.details_title}>
                WHAT WILL WE DO WITH THE BABY O?
            </div>
            <div className={styles.details}>
                <div className={styles.details_text}>
                    <div>
                        Illustrated by Jennifer Herbert. Published by Tundra Books, 2004.
                    </div>
                    <br></br>
                    <div>
                        What, indeed, will we do with the Baby-o when he's just beginning to walk? 
                    </div>
                </div>
                <img className={styles.details_book_cover} src={babyO} alt="'Baby O' book cover"></img>
            </div>
            <div className={styles.purchase_links}>
                <a target="_blank" rel="noreferrer" href="https://a.co/d/60gs0Ml">
                    AMAZON LINK
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.indigo.ca/en-ca/what-will-we-do-with-the-baby-o/9780887766893.html">
                    INDIGO LINK
                </a>
            </div>
            <div className={styles.reviews_section}>
                <div className={styles.reviews_title}>
                    REVIEWS
                </div>
                <div className={styles.reviews}> 
                    <div>
                        <div>
                            "As a one-time children's librarian and a new parent, 
                            I thoroughly enjoyed this collaboration. The mix of traditional pieces such as 
                            'Hush Little Baby' and more recent selections, like 'Jig Along Home', will 
                            provide most readers with comfortable familiarity while possibly introducing 
                            some new rhymes and songs. . . . This title is also a nice choice for gift giving, 
                            especially for new parents. Highly Recommended."
                        </div>
                        <div>
                            &emsp;~ Catherine Hoyt, CM: an Electronic <br></br>&emsp; Reviewing Journal of Canadian Materials for <br></br>&emsp; Young People, Jan 7, 2005.
                        </div>
                    </div>
                    <div>
                        <div>
                        "Toronto children's librarian Theo Heras has compiled a lovely selection of 10 
                        rhymes and songs for very young children."
                        </div>
                        <div>
                            &emsp;~ Marnie Parsons, Quill & Quire, September <br></br>&emsp; 2004
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}