
import React from "react";
import "../styles/App.css";
import styles from "../styles/HomeSlideshow.module.css";
import contraries from "../assets/wordsInWool/contraries_cropped.jpg";
import there from "../assets/wordsInWool/there_cropped.jpg";
import location from "../assets/wordsInWool/location_cropped.jpg";
import omit from "../assets/wordsInWool/omit_cropped.jpg";
import books from "../assets/wordsInWool/canvas_cropped.jpg";
import songs from "../assets/wordsInWool/songs_cropped.jpg";
import theatre from "../assets/wordsInWool/theatre_cropped.jpg";
import sound from "../assets/wordsInWool/sound_cropped.jpg";
import blooming from "../assets/wordsInWool/blooming_cropped.jpg"
import copacetic from "../assets/wordsInWool/copacetic_cropped.jpg";
import imagine from "../assets/wordsInWool/imagine_cropped.jpg";
import covid from "../assets/wordsInWool/covid_cropped.jpg";
import piano from "../assets/wordsInWool/piano_cropped.jpg";

const images = [contraries, there, location, omit, books, songs, theatre, sound, blooming, copacetic, imagine, covid, piano];
const delay = 2500;

export default function HomeSlideshow() {
  const [index, setIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className={styles.slideshow}>
      <div
        className={styles.slideshowSlider}
        style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
      >
        {images.map((image, index) => (
          <img key={index} className={styles.slide} src={image} alt="words in wool"></img>
        ))}
      </div>
    </div>
  );
}