import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import About from "./pages/About";
import Books from "./pages/Books";
import Needlepoint from "./pages/Needlepoint";
import WordsInWool from "./pages/WordsInWool";
import Contact from "./pages/Contact";
import BabyCakes from "./pages/books/Baby_Cakes";
import BabyO from "./pages/books/Baby_O";
import Bunny from "./pages/books/Bunny";
import Hat from "./pages/books/Hat";
import Kittens from "./pages/books/Kittens";
import Puppy from "./pages/books/Puppy";
import Haircut from "./pages/books/The_Haircut"
import Footer from "./components/Footer";
import "./styles/App.css";

function App() {
  return (
    <BrowserRouter className="app_container">
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/books" element={<Books />} />
          <Route path="/needlepoint" element={<Needlepoint />} />
          <Route path="/needlepoint/words-in-wool" element={<WordsInWool />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/books/baby-cakes" element={<BabyCakes />} />
          <Route path="/books/baby-o" element={<BabyO />} />
          <Route path="/books/wheres-bunny" element={<Bunny />} />
          <Route path="/books/hat-on-hat-off" element={<Hat />} />
          <Route path="/books/our-new-kittens" element={<Kittens />} />
          <Route path="/books/my-puppy-patch" element={<Puppy />} />
          <Route path="/books/the-haircut" element={<Haircut />} />
        </Routes>
        <Footer></Footer>
    </BrowserRouter>
  );
}

export default App;
