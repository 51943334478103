import "../styles/App.css";
import styles from "../styles/About.module.css";
import headshot from "../assets/biography/theo_now.jpeg";
import sixMonths from "../assets/biography/six_months_theo.jpeg";
import toddler from "../assets/biography/toddler_theo.jpeg";
import classPhoto from "../assets/biography/class_photo.jpeg";
import harrison from "../assets/biography/harrison.jpg";
import roosevelt from "../assets/biography/roosevelt.jpg";
import teen from "../assets/biography/teenager_theo.jpeg";
import classical from "../assets/biography/classical.jpeg";
import librarian from "../assets/biography/librarian.jpeg";
import redArrow1 from "../assets/biography/red_arrow_one.png";
import redArrow2 from "../assets/biography/red_arrow_2.png";
import yellowArrow1 from "../assets/biography/yellow_arrow_1.png";
import yellowArrow2 from "../assets/biography/yellow_arrow_2.png";
import greenArrow1 from "../assets/biography/green_arrow_1.png";
import greenArrow2 from "../assets/biography/green_arrow_2.png";
import blueArrow1 from "../assets/biography/blue_arrow_1.png";

export default function About() {
  return (
    <div className={styles.about_container}>
      <div className={styles.about_title}>
        A LITTLE BIT ABOUT ME
      </div>
      <div className={styles.introduction}>
        <div>
          My name is Theo Heras. I am a children's book author, artist, and singer. Here's my story so far!
        </div>
        <img className={styles.headshot_image} src={headshot} alt="Theo headshot"></img>
      </div>

      <div className={styles.timeline_section_reverse}>
        <img className={styles.vertical_image} src={sixMonths} alt="Me at 6 months" ></img>
        <div className={styles.vertical}>
          <div>
            <div>
              I was born in Newburyport, Massachusetts.
            </div>
            <div>
              Here I am at about 6 months. I had a lot of hair!
            </div>
          </div>
          <img  className={styles.red_arrow_1} src={redArrow1} alt="Red arrow"></img>
        </div>
      </div>

      <div className={styles.timeline_section}>
        <div className={styles.vertical}>
          <div>
            My family moved to East Chicago, Indiana, where I grew.
          </div>
          <img  className={styles.yellow_arrow_1} src={yellowArrow1} alt="Yellow arrow"></img>
        </div>
        <img className={styles.vertical_image} src={toddler} alt="Me as a toddler" ></img>
      </div>

      <div className={styles.timeline_section}>
        <img className={styles.horizontal_image} src={classPhoto} alt="My second grade glass" ></img>
        <img className={styles.green_arrow_1} src={greenArrow1} alt="Green arrow"></img>
      </div>

      <div className={styles.timeline_section_reverse}>
        <img className={styles.horizontal_image} src={harrison} alt="Harrison public school" ></img>
        <div className={styles.horizontal}>
          <div>
            <div>
              Above is my second grade class 
            </div>
            <div>
              photo.
            </div>
            <div>
              I went to Harrison Public School.
            </div>
          </div>
          <img className={styles.blue_arrow_1} src={blueArrow1} alt="Blue arrow"></img>
        </div>
      </div>

      <div className={styles.timeline_section}>
        <div className={styles.horizontal}>
          <div>
            <div>
              Then I went to Roosevelt High School
            </div>
            <div>
              We moved back East when I was a teenager
            </div>
          </div>
          <img className={styles.red_arrow_2} src={redArrow2} alt="Red arrow"></img>
        </div>
        <img className={styles.horizontal_image} src={roosevelt} alt="Roosevelt high school" ></img>
      </div>

      <div>
        A typical teenager on the phone!
      </div>

      <div className={styles.timeline_section}>
        <img className={styles.horizontal_image} src={teen} alt="Me as teenager" ></img>
        <img className={styles.yellow_arrow_2} src={yellowArrow2} alt="Yellow arrow" ></img>
      </div>

      <div className={styles.timeline_section}>
        <img className={styles.vertical_image} src={classical} alt="Classical high school" ></img>
        <div className={styles.vertical}>
          <div>
            Next, I went to Classical High School
          </div>
          <img className={styles.green_arrow_2} src={greenArrow2} alt="Green arrow"></img>
        </div>
      </div>

      <div className={styles.timeline_section}>
        <div className={styles.vertical}>
          <div>
            <div>
              I got married and had a family. I became a librarian when my kids were teenagers.
            </div>
            <br>
            </br>
            <div>
              Still lots of hair and lots of books!
            </div>
          </div>
        </div>
        <img className={styles.vertical_image} src={librarian} alt="Librarian pictures" ></img>
      </div>
    </div>
  );
}