import { useEffect } from "react";
import BackButton from "../../components/GoBack";
import styles from "../../styles/BooksDetails.module.css";
import haircut from "../../assets/bookCovers/the_haircut.jpg";

export default function TheHaircut() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.books_details_container}>
            <BackButton></BackButton>
            <div className={styles.details_title}>
                THE HAIRCUT
            </div>
            <div className={styles.details}>
                <div className={styles.details_text}>
                    <div>
                        Illustrated by Renné Benoit. Published by Pajama Press in 2020.
                    </div>
                    <br></br>
                    <div>
                        Uh oh, hair in the eyes-- it's time for this little one's first haircut. 
                        The barbershop comes alive with sensory details as he watches other customers and bravely takes his own turn in the chair. 
                        Clip, clip, clip... now there is a big boy in the mirror!
                    </div>
                </div>
                <img className={styles.details_book_cover} src={haircut} alt="'Baby Cakes' book cover"></img>
            </div>
            <div className={styles.purchase_links}>
                <a target="_blank" rel="noreferrer" href="https://a.co/d/ar7KlcF">
                    AMAZON LINK
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.indigo.ca/en-ca/haircut/9781772780956.html">
                    INDIGO LINK
                </a>
            </div>
            <div className={styles.reviews_section}>
                <div className={styles.reviews_title}>
                    REVIEWS
                </div>
                <div className={styles.reviews}> 
                    <div>
                        <div>
                            "What a wonderful book to read with toddlers. . . who will be experiencing their first haircut. 
                            It speaks to them, not their parents who think their hair is too long or wants it styled or thinks it’s time. 
                            It’s time because the little boy’s hair keeps falling in his eyes and gets in the way of his play. . .  
                            The Haircut is. . . comforting and informing. [The] book cover portrait that focuses on the child and his 
                            glorious tresses and doubtful face invites the reader in, and Renné Benoit’s watercolour and digital illustrations are similarly engaging, 
                            both uncluttered and complete. . . Whether a parent wants to help a child to prepare for that first haircut or to reminisce about that first experience, 
                            The Haircut is there for a snuggly read."
                        </div>
                        <div>
                            &emsp;~ CanLit for Little Canadians, May 25, 2020
                        </div>
                    </div>
                    <div>
                        <div>
                            "A little boy constantly has to push the hair back from his eyes as he plays, 
                            and so his dad decides that it’s time for a trip to the barber shop. . . . 
                            Told with simplicity in short, clipped sentences, The Haircut will be relatable to toddlers, 
                            many of whom will have some anxiety about getting their hair cut for the first time. 
                            The large book format, with padded covers and rounded corners, is perfect for little ones, 
                            and the heavy pages make it both durable and easy for toddlers to turn the pages. 
                            Renné Benoit’s soft watercolor illustrations, rendered in muted shades of blue, 
                            tan and sienna with pops of red, add charm to the story. Since babies and toddlers love to look at faces, 
                            the cover, showing a close-up of the little boy in the barber’s chair, will attract their attention. Recommended."
                        </div>
                        <div>
                            &emsp;~ CM Magazine, May 15, 2020
                        </div>
                    </div>
                    <div>
                        <div>
                            ". . . The text is wonderfully spare – this would be a delightful pick for a baby or toddler time, 
                            where attention spans are often limited, to say the least! Heras was a children’s librarian for 
                            24 years, and it shows in her writing – she really knows what makes for a successful book for 
                            the littlest listeners. And as always, Benoit’s illustrations are charming – 
                            everything she creates is just the sweetest!"
                        </div>
                        <div>
                            &emsp;~ Raincity Librarian, June 1, 2020
                        </div>
                    </div>
                    <div>
                        <div>
                            "Filled with humour and honesty, the book sets up an atmosphere where it’s okay to feel your 
                            feelings and that the adults are there to be supportive and gentle. 
                            Once the character moves through his initial discomfort, Heras and Benoit are able to 
                            re-establish the playfulness they created earlier in the story…."
                        </div>
                        <div>
                            &emsp;~ Canadian Children’s Book News, Fall 2020
                        </div>
                    </div>
                    <div>
                        <div>
                            "A great book for any library or home collection."
                        </div>
                        <div>
                            &emsp;~ Canadian Bookworm, June 12, 2020
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}