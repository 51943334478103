import { useNavigate } from 'react-router-dom';
import haircut from "../assets/bookCovers/the_haircut.jpg";
import puppy from "../assets/bookCovers/my_puppy_patch.jpg";
import kittens from "../assets/bookCovers/our_new_kittens.jpg";
import bunny from "../assets/bookCovers/wheres_bunny.jpg";
import babyCakes from "../assets/bookCovers/baby_cakes.jpg";
import hat from "../assets/bookCovers/hat_on_hat_off.jpg";
import baby from "../assets/bookCovers/what_will_we_do_with_the_baby_o.jpg";
import "../styles/App.css";
import styles from "../styles/Books.module.css";

export default function Books() {
  const navigate = useNavigate();

  return (
    <div className={styles.books_container}>
        <div className={styles.books_text}>
          <div>
            My journey as a childrens' book author began in 2004 with the sing-along stories of
            <i> What Will We Do With The Baby O?</i>. I have had six more books published between 2016 and 2020. 
            My aim in writing books for little ones is to engage and entertain them and their parents with 
            stories that reflect real childhood experiences, from the first haircut to taking the first steps.
          </div>
          <br></br>
          <div>
            Below are my works in order of most to least recent. Click on the book covers for more details.
          </div>
        </div>
        <hr className={styles.books_hr}></hr>
        <div className={styles.book_title}>
          THE HAIRCUT
        </div>
        <div className={styles.book_section}>
          <div>
              <img className={styles.book_cover} src={haircut} alt="'The Haircut' book cover" onClick={() => navigate('the-haircut')}></img>
          </div>
          <div className={styles.book_section_text}>
            <div>
              Illustrated by Renné Benoit. Published by Pajama press, 2020.
            </div>
            <br></br>
            <div>
              Uh oh, hair in the eyes-- it's time for this little one's first haircut. 
              The barbershop comes alive with sensory details as he watches other customers and bravely takes his own turn in the chair. 
              Clip, clip, clip... now there is a big boy in the mirror!
            </div>
          </div>
        </div>
        <hr className={styles.books_hr}></hr>
        <div className={styles.book_title}>
          MY PUPPY PATCH
        </div>
        <div className={styles.book_section_reverse}>
          <div className={styles.book_section_text}>
              <div>
                Illustrated by Alice Carter. Published by Pajama Press, 2019. 
              </div>
              <br></br>
              <div>
                One intrepid bespeckled girl trains her new puppy Patch. 
                Shortlisted for the 2020 IODE Jean Throop Book Award.
              </div>
          </div>
          <div>
              <img className={styles.book_cover} src={puppy} alt="'My Puppy Patch'book cover" onClick={() => navigate('my-puppy-patch')}></img>
          </div>
        </div>
        <hr className={styles.books_hr}></hr>
        <div className={styles.book_title}>
          OUR NEW KITTENS
        </div>
        <div className={styles.book_section}>
          <div>
              <img className={styles.book_cover} src={kittens} alt="'Our New Kittens' book cover" onClick={() => navigate('our-new-kittens')}></img>
          </div>
          <div className={styles.book_section_text}>
              <div>
                Illustrated by Alice Carter. Pajama Press, 2018. 
              </div>
              <br></br>
              <div>
                Two brothers wait impatiently to bring home two tiny kittens. 
                Taking care of new kittens is a lot of work; and it's fun to watch the kittens get into mischief too. 
                Still one question remains: What will the brothers name their kittens?
              </div>
          </div>
        </div>
        <hr className={styles.books_hr}></hr>
        <div className={styles.book_title}>
          WHERE'S BUNNY?
        </div>
        <div className={styles.book_section_reverse}>
          <div className={styles.book_section_text}>
            <div>
              Illustrated by Renné Benoit and published by Pajama Press, 2018. 
            </div>
            <br></br>
            <div>
              Our delightful siblings are getting ready for bed. All is well, except -- Where's Bunny? 
              Exactly where he should be, snuggling in bed.
            </div>
          </div>
          <div>
              <img className={styles.book_cover} src={bunny} alt="'Where's Bunny?'book cover" onClick={() => navigate('wheres-bunny')}></img>
          </div>
        </div>
        <hr className={styles.books_hr}></hr>
        <div className={styles.book_title}>
          BABY CAKES
        </div>
        <div className={styles.book_section}>
          <div>
              <img className={styles.book_cover} src={babyCakes} alt="'Baby Cakes' book cover" onClick={() => navigate('baby-cakes')}></img>
          </div>
          <div className={styles.book_section_text}>
            <div>
              Illustrated by Renné Benoit and published by Pajama Press, 2017.
            </div>
            <br></br>
            <div>
              The intrepid duo of Hat On, Hat Off are in the kitchen baking up a storm. 
              If only kitty could stay out of trouble! 
            </div>
          </div>
        </div>
        <hr className={styles.books_hr}></hr>
        <div className={styles.book_title}>
          HAT ON, HAT OFF
        </div>
        <div className={styles.book_section_reverse}>
          <div className={styles.book_section_text}>
            <div>
              Illustrated by Renné Benoit and published by Pajama Press, 2016.
            </div>
            <br></br>
            <div>
            The title says it all. Getting a toddler ready for a walk entails trying to keep a hat on.
            </div>
          </div>
          <div>
              <img className={styles.book_cover} src={hat} alt="'Hat On Hat Off' book cover" onClick={() => navigate('hat-on-hat-off')}></img>
          </div>
        </div>
        <hr className={styles.books_hr}></hr>
        <div className={styles.book_title}>
          WHAT WILL WE DO WITH THE BABY-O?
        </div>
        <div className={styles.book_section}>
          <div>
              <img className={styles.book_cover} src={baby} alt="'What Will We Do With The Baby O' book cover" onClick={() => navigate('baby-o')}></img>
          </div>
          <div className={styles.book_section_text}>
            <div>
              Illustrated by Jennifer Herbert and published by Tundra Books, 2004. 
            </div>
            <br></br>
            <div>
              What, indeed, will we do with the Baby-o when he's just beginning to walk? 
              Follow Baby-o as he wrecks havoc in the kitchen and playroom, frightens his older twin sisters on the stairs and in the park, and exhausts his doting parents. 
              Ten traditional songs and rhymes, from the rousing "What Will We Do with the Baby-o?" to the lilting "The Riddle Song (I Gave My Love a Cherry)", provide the "soundtrack" of the baby's day. 
              Simple scores at the back of the book encourage singing and playing the songs. Illustrations by Jennifer Herbert are vibrant, lively and witty. This is a book to be shared in the morning, in the afternoon, in the car, and especially at bedtime.
            </div>
          </div>
        </div>
    </div>
  );
}