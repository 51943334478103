import { useEffect } from "react";
import "../styles/App.css";
import styles from "../styles/WordsInWool.module.css";
import contraries from "../assets/wordsInWool/contraries.jpg";
import there from "../assets/wordsInWool/there.jpg";
import location from "../assets/wordsInWool/location.jpg";
import omit from "../assets/wordsInWool/omit.jpg";
import books from "../assets/wordsInWool/books.jpg";
import canvas from "../assets/wordsInWool/canvas.jpg";
import songs from "../assets/wordsInWool/songs.jpg";
import theatre from "../assets/wordsInWool/theatre.jpg";
import sound from "../assets/wordsInWool/sound.jpg";
import blooming from "../assets/wordsInWool/blooming.jpg";
import copacetic from "../assets/wordsInWool/copacetic.jpg";
import imagine from "../assets/wordsInWool/imagine.jpg";
import covid from "../assets/wordsInWool/covid.jpg";
import piano from "../assets/wordsInWool/piano.jpg";

export default function WordsInWool() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return (
    <div className={styles.wiw_container}>
        <div className={styles.wiw_intro}>
            Inspired in 2017 by an exhibit at the Textile Museum of Canada entitled 
            “Kind Words Can Never Die: A Personal Collection of Victorian Needlework,” 
            I began my own project of capturing quotations on canvas. 
            Fourteen unique pieces comprise the series I call “Words in Wool.” 
            I began in 2017 and finished the last piece in 2022. 
            All of the quotations I interpreted in needlepoint have inspired me: 
            Some I learned from friends and colleagues. Others from things I read. 
            One was on a sweatshirt! And I found at least one written on a wall in New York City. 
            The pieces were beautifully photographed by Bernard Kelly and published in a book 
            called <i>Words in Wool</i>. Here are the photographs.
        </div>
        <hr></hr>
        <div className={styles.wiw_grid}>
            <div>
                <img className={styles.horizontal_image} src={contraries} alt="Without contraries is no progression"></img>
                <div>
                    Without Contraries is no Progression
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={there} alt="There's no There There"></img>
                <div>
                    There's no There There
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={location} alt="Location, Location, Location"></img>
                <div>
                    Location, Location, Location
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={omit} alt="Omit Unnecessary Words"></img>
                <div>
                    Omit Unnecessary Words
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={books} alt="So Many Books, So Little Time"></img>
                <div>
                    So many books, so little time
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={canvas} alt="Life is a big canvas and you should throw all the paint on it you can"></img>
                <div>
                    Life is a big canvas and you should throw all the paint on it you can
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={songs} alt="Let me make the Songs of a nation and I care not who makes its Laws"></img>
                <div>
                    Let me make the Songs of a nation and I care not who makes its Laws
                </div>
            </div>
            <div>
                <img className={styles.vertical_image} src={theatre} alt="Tragedy Tomorrow, Comedy Tonight (in Greek)"></img>
                <div>
                Tragedy Tomorrow, Comedy Tonight (in Greek)
                </div>
            </div>
            <div>
                <img className={styles.vertical_image} src={sound} alt="Take Care of the Sound and the Sense Will Take Care of Itself / Take care of the sense and the sound will take care of itself."></img>
                <div>
                    Take Care of the Sound and the Sense Will Take Care of Itself / <br></br>Take care of the sense and the sound will take care of itself.
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={blooming} alt="Late Blooming Is Still Blooming"></img>
                <div>
                    Late Blooming Is Still Blooming
                </div>
            </div>
            <div>
                <img className={styles.vertical_image} src={copacetic} alt="It's Copacetic (Bill Robinson)"></img>
                <div>
                    It's Copacetic (Bill Robinson)
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={imagine} alt="Imagine"></img>
                <div>
                    Imagine
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={covid} alt="Covid"></img>
                <div>
                    Covid
                </div>
            </div>
            <div>
                <img className={styles.horizontal_image} src={piano} alt="The Piano ain't got no wrong Notes"></img>
                <div>
                The Piano ain't got no wrong Notes
                </div>
            </div>
        </div>
    </div>
  );
}