import { useEffect } from "react";
import BackButton from "../../components/GoBack";
import styles from "../../styles/BooksDetails.module.css";
import puppy from "../../assets/bookCovers/my_puppy_patch.jpg";

export default function MyPuppyPatch() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.books_details_container}>
            <BackButton></BackButton>
            <div className={styles.details_title}>
                MY PUPPY PATCH
            </div>
            <div className={styles.details}>
                <div className={styles.details_text}>
                    <div>
                        Illustrated by Alice Carter. Published by Pajama Press in 2019.
                    </div>
                    <br></br>
                    <div>
                        One intrepid bespeckled girl trains her new puppy Patch. 
                        Shortlisted for the 2020 IODE Jean Throop Book Award.
                    </div>
                </div>
                <img className={styles.details_book_cover} src={puppy} alt="'Baby Cakes' book cover"></img>
            </div>
            <div className={styles.purchase_links}>
                <a target="_blank" rel="noreferrer" href="https://a.co/d/ekqjOaX">
                    AMAZON LINK
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.indigo.ca/en-ca/my-puppy-patch/9781772780802.html">
                    INDIGO LINK
                </a>
            </div>
            <div className={styles.reviews_section}>
                <div className={styles.reviews_title}>
                    REVIEWS
                </div>
                <div className={styles.reviews}> 
                    <div>
                        <div>
                            "My Puppy Patch would be a great choice for a family looking to bring a new dog 
                            into the home or for any child who loves  pets. Just enough tension, adorable pictures, 
                            and a sweet simple story make it perfect for multiple read-throughs."
                        </div>
                        <div>
                            &emsp;~ CM, April 19, 2019
                        </div>
                    </div>
                    <div>
                        <div>
                            "This delightful story . . . is a great introduction for young children 
                            about caring for and training a puppy. . ."
                        </div>
                        <div>
                            &emsp;~ 49th Shelf, June 2019
                        </div>
                    </div>
                    <div>
                        <div>
                            "A young child is confident a new puppy will adhere to newly learned rules on a first 
                            outing beyond the backyard fence. . .  .  The genuine love expressed between owner and 
                            pet fortifies the responsibilities Patch's owner undertakes."
                        </div>
                        <div>
                            &emsp;~ Kirkus, June 13, 2019
                        </div>
                    </div>
                    <div>
                        <div>
                            "This simple story about children and raising canine family members touches on 
                            topics such as socialization, training and  caring for a puppy."
                        </div>
                        <div>
                            &emsp;~ Toronto.com, July 18, 2019
                        </div>
                    </div>
                    <div>
                        <div>
                            "I liked that the story showed good ways to look after a pet, 
                            and the bond that develops between the child and the dog."
                        </div>
                        <div>
                            &emsp;~ Canadian Bookworm, August 6, 2019
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}