import { NavLink, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import "../styles/App.css";
import styles from "../styles/Header.module.css";

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

export default function Header() {
    const location = useLocation();

    const { height, width } = useWindowDimensions();
    
    const [headerWidth, setHeaderWidth] = useState(0);
    const [open, setOpen] = useState(false);
    const [dropdownHeight, setDropdownHeight] = useState(0);

    function handleNeedlepointDropdown(newPage) {
        if(open) {
            setDropdownHeight(0);
            setOpen(false);
        } else if (!newPage){
            setDropdownHeight('6.5rem');
            setOpen(true);
        }
    }
    
    return (
    <div className={styles.header_container}>
        <div className={styles.title_container}>
            <div className={styles.open_menu} onClick={() => setHeaderWidth('12.5rem')}>&#9776;</div>
            <div className={styles.title}></div>
        </div>
        <div className={styles.nav_container} style={{'width' : `${width < 800 ? headerWidth : '60rem'}`}}>
            <div className={styles.closebtn} onClick={() => setHeaderWidth(0)}></div>
            <NavLink 
                to="/" 
                className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                onClick={() => {setHeaderWidth(0); handleNeedlepointDropdown(true);}}
            >
                HOME
            </NavLink>
            <NavLink 
                to="/about" 
                className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                onClick={() => {setHeaderWidth(0); handleNeedlepointDropdown(true);}}
            >
                ABOUT
            </NavLink>
            <NavLink 
                to="/books" 
                className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                onClick={() => {setHeaderWidth(0); handleNeedlepointDropdown(true);}}
            >
                BOOKS
            </NavLink>
            <a 
                className={location.pathname === '/needlepoint' || location.pathname === '/needlepoint/words_in_wool' ? styles.active : styles.inactive}
                onClick={() => {handleNeedlepointDropdown(false);}}
            >
                NEEDLEPOINT
            </a>
            <div style={{'height' : dropdownHeight}} className={styles.dropdown_content}>
                <NavLink 
                    to="/needlepoint" 
                    onClick={() => {setHeaderWidth(0); handleNeedlepointDropdown(true);}}
                >
                    GENERAL
                </NavLink>
                <NavLink 
                    to="/needlepoint/words-in-wool" 
                    onClick={() => {setHeaderWidth(0); handleNeedlepointDropdown(true);}}
                >
                    WORDS IN WOOL
                </NavLink>
            </div>
            <NavLink 
                to="/contact" 
                className={({ isActive }) => (isActive ? styles.active : styles.inactive)}
                onClick={() => {setHeaderWidth(0); handleNeedlepointDropdown(true);}}
            >
                CONTACT
            </NavLink>
        </div>
    </div>
  );
}