import { useEffect } from "react";
import BackButton from "../../components/GoBack";
import styles from "../../styles/BooksDetails.module.css";
import bunny from "../../assets/bookCovers/wheres_bunny.jpg";

export default function WheresBunny() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.books_details_container}>
            <BackButton></BackButton>
            <div className={styles.details_title}>
                WHERE'S BUNNY?
            </div>
            <div className={styles.details}>
                <div className={styles.details_text}>
                    <div>
                    Illustrated by Renné Benoit. Published by Pajama Press in 2018.
                    </div>
                    <br></br>
                    <div>
                    Our delightful siblings are getting ready for bed. All is well, except -- 
                    Where's Bunny? Exactly where he should be, snuggling in bed.
                    </div>
                </div>
                <img className={styles.details_book_cover} src={bunny} alt="'Where's Bunny' book cover"></img>
            </div>
            <div className={styles.purchase_links}>
                <a target="_blank" rel="noreferrer" href="https://a.co/d/aMtLGEw">
                    AMAZON LINK
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.indigo.ca/en-ca/wheres-bunny/9781772782257.html">
                    INDIGO LINK
                </a>
            </div>
            <div className={styles.reviews_section}>
                <div className={styles.reviews_title}>
                    REVIEWS
                </div>
                <div className={styles.reviews}> 
                    <div>
                        <div>
                            "Theo Heras makes her text simple and readable for those just learning to decipher books, 
                            and it is sweetly appropriate for a concept book about bedtime routines. . . . 
                            Thankfully Theo Heras does more than just assert a concept. There is a story here, 
                            one of sibling affection and a young child's bond to his stuffed animal, 
                            that is elevated with Renné Benoit's artwork. . . . If the affection so captivated 
                            in Renné Benoit's waterolor and digital artwork could extend beyond the siblings, 
                            it would be sure to include their books. Like the words and art of Where's Bunny?, 
                            the book says, "Hug me" and the very young will be sure to oblige at least once before lights out."
                        </div>
                        <div>
                            &emsp;~ Helen Kubiw, CanLit for LittleCanadians, <br></br>&emsp; March 12, 2018
                        </div>
                    </div>
                    <div>
                        <div>
                            "Simple phrases ("Warm water tickles toes") are interspersed with declaratory exclamations 
                            ("Soapy suds, not in eyes!"). By using a warm color palette without strong constrasts, 
                            the watercolor-and-digital art suggests coziness, happiness, and familial love. 
                            VERDICT: A soothing bedtime story perfect for one-on-one sharing with babies, toddlers, 
                            and preschoolers alike."
                        </div>
                        <div>
                            &emsp;~ Sally James, School Library Journal, March <br></br>&emsp; 1, 2018
                        </div>
                    </div>
                    <div>
                        <div>
                            "Told with sparse language, Where's Bunny? will appeal to the very young. . . . 
                            [A]n ideal bedtime book. . . . 
                            Young children are sure to be soothed by the content, pace, and illustration of Where's Bunny?"
                        </div>
                        <div>
                            &emsp;~ Roxy Garstad, CM Magazine, February 23, <br></br>&emsp; 2018
                        </div>
                    </div>
                    <div>
                        <div>
                            "A nicely child-centered iteration on a common theme."
                        </div>
                        <div>
                            &emsp;~ Kirkus, Febrauary 4, 2018
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}