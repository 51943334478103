import "../styles/App.css";
import styles from "../styles/Contact.module.css";

export default function Contact() {
  return (
    <div className={styles.contact_container}>
      <div className={styles.email}>
        Email: theoheras@gmail.com
      </div>
      <hr className={styles.contact_hr}></hr>
      <div className={styles.links}>
        <div className={styles.links_title}>
          Organizations and links:
        </div>
        <br></br>
        <div className={styles.links_list}>
          <div>
            <a href="https://www.canscaip.org/" target="_blank" rel="noreferrer">Canadian Society of Children's Authors, Illustrators and Performers</a>
          </div>
          <br></br>
          <div className={styles.ibby}>
            <a href="https://www.canscaip.org/" target="_blank" rel="noreferrer">IBBY Canada</a>
          </div>
          <br></br>
          <div className={styles.wuc}>
            <a href="https://www.writersunion.ca/" target="_blank" rel="noreferrer">The Writers Union of Canada</a>
          </div>
          <br></br>
          <div className={styles.scbui}>
            <a href="https://www.scbwi.org/" target="_blank" rel="noreferrer">The Society of Children's Book Writers and Illustrators</a>
          </div>
          <br></br>
          <div className={styles.ccbc}>
            <a href="https://bookcentre.ca/" target="_blank" rel="noreferrer">The Canadian Children's Book Centre</a>
          </div>
          <br></br>
          <div className={styles.obecb}>
            <a href="https://www.torontopubliclibrary.ca/osborne/" target="_blank" rel="noreferrer">Osborne Collection of Early Children's Books</a>
          </div>
        </div>
      </div>
    </div>
  );
}