import { useEffect } from "react";
import BackButton from "../../components/GoBack";
import styles from "../../styles/BooksDetails.module.css";
import kittens from "../../assets/bookCovers/our_new_kittens.jpg";

export default function OurNewKittens() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className={styles.books_details_container}>
            <BackButton></BackButton>
            <div className={styles.details_title}>
                OUR NEW KITTENS
            </div>
            <div className={styles.details}>
                <div className={styles.details_text}>
                    <div>
                        Illustrated by Alice Carter. Published by Pajama Press in 2018.
                    </div>
                    <br></br>
                    <div>
                        Two brothers wait impatiently to bring home two tiny kittens. 
                        Taking care of new kittens is a lot of work; and it's fun to watch the kittens get into mischief too. 
                        Still one question remains: What will the brothers name their kittens?
                    </div>
                </div>
                <img className={styles.details_book_cover} src={kittens} alt="'Our New Kittens' book cover"></img>
            </div>
            <div className={styles.purchase_links}>
                <a target="_blank" rel="noreferrer" href="https://a.co/d/5naBXVC">
                    AMAZON LINK
                </a>
                <a target="_blank" rel="noreferrer" href="https://www.indigo.ca/en-ca/our-new-kittens/9781772780604.html">
                    INDIGO LINK
                </a>
            </div>
            <div className={styles.reviews_section}>
                <div className={styles.reviews_title}>
                    REVIEWS
                </div>
                <div className={styles.reviews}> 
                    <div>
                        <div>
                            "Through this lovely introduction to pet care and responsibility. . . 
                            children will learn how to safely care for new furry  friends."
                        </div>
                        <div>
                            &emsp;~ Forward Review, October 26, 2018
                        </div>
                    </div>
                    <div>
                        <div>
                            "Readers who clamour for sweet, realistic-leaning stories to do with kittens 
                            or cats or for picture books about taking care of  pets might especially adore 
                            the sheer cuddliness of Our New Kittens"
                        </div>
                        <div>
                            &emsp;~ Fab Book Reviews, January 27, 2019
                        </div>
                    </div>
                    <div>
                        <div>
                            "A good story about the anticipation and excitment of getting a pet, 
                            and of kids learning how to interact with their new  charges."
                        </div>
                        <div>
                            &emsp;~ Booklist, February 7, 2019
                        </div>
                    </div>
                    <div>
                        <div>
                            "Our New Kittens is a good book with a 
                            simple story about the joys of having 4-legged family members."
                        </div>
                        <div>
                            &emsp;~ Our Windsor, March 22, 2019
                        </div>
                    </div>
                    <div>
                        <div>
                            "Heras depicts a very realistic story about raising pets including the cuddly times of 
                            holiding them while they sleep, the  messy times of cleaning the litter box, 
                            as well as the responsible times of feeding them."
                        </div>
                        <div>
                            &emsp;~ CM, October 26, 2019
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}