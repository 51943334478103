import { useNavigate } from 'react-router-dom';
import "../styles/App.css";
import styles from "../styles/Home.module.css";
import HomeSlideshow from "../components/HomeSlideshow";
import haircut from "../assets/bookCovers/the_haircut.jpg";

export default function Home() {
  const navigate = useNavigate();
  return (
    <div className={styles.home_container}>
      <div className={styles.section_subtitle}>
        MY LATEST BOOK
      </div>
      <div className={styles.section_title}>
          The Haircut
      </div>
      <div className={styles.book_content}>
        <img className={styles.book_cover} src={haircut} alt="'The Haircut' book cover"></img>
        <div className={styles.book_text}>  
          Uh oh, hair in the eyes-- it's time for this little one's first haircut. 
          The barbershop comes alive with sensory details as he watches other customers and bravely takes his own turn in the chair. 
          Clip, clip, clip... now there is a big boy in the mirror!
        </div>
      </div>
      <div className={styles.route_button} onClick={() => navigate('/books/the-haircut')}>See More</div>
      <hr></hr>
      <div className={styles.section_subtitle}>
        RECENT EXHIBIT
      </div>
      <div className={styles.wiw_content}>
        <div className={styles.section_title}>
          Words In Wool
        </div>
        <HomeSlideshow></HomeSlideshow>
        <div className={styles.wiw_description}>
            The book is now available. To purchase, contact me at: theoheras@gmail.com.
        </div>
        <div className={styles.route_button} onClick={() => navigate('/needlepoint/words-in-wool')}>See More</div>
      </div>
    </div>
  );
}
